import React from 'react';
import { Button } from '@mui/joy';
import ophie from '../asset/ophie.jpg';
import { useNavigate } from 'react-router-dom';

export default function ImageAiContainer() {
    const navigate = useNavigate();
    const handleButtonClick = (location) => {
        // Navigate to the 'imageai' route when the button is clicked
        navigate(location);
      };
  return (
    <div id='textai'className='textAiContainer' style={{ display:'flex', alignItems: 'center' , backgroundColor:'white', height:'400px'}}>
      <div style={{ width: '50%' }} className='textAiContainerLeft'>
        <img src={ophie} alt="Ophie" />
      </div>
      <div className='textAiContainerRight'>
        <div style={{width:'400px'}}>
        <h4>WELCOME</h4>
        <h4 style={{fontWeight:'bold'}}>ChatGPT</h4>
        <p style={{marginBottom:'25px', marginTop:'25px'}}>
          Engage in natural language conversations with our advanced ChatGPT. Ask questions, get creative, and experience the capabilities of language AI.
        </p>
        <button className='explore-button' onClick={() => handleButtonClick('/textai')}>Start Chatting</button>
        </div>
      </div>
    </div>
  );
}
