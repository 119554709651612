import React from 'react'
import { Link } from 'react-scroll';

export default function ColorCard(props) {
    const {id,title,description,color,index,to} = props
  return (
    <Link className='colorCardItem' 
      style={{
        backgroundColor: `${color}`,
        width: '33.33%',
        color: 'white',
        fontWeight: 'bold',
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
     to={to}
     smooth={true} duration={100} offset={5}
    >
    
    <div  key={index}>
      <p style={{
        backgroundColor: 'white',
        width: '50px',
        height: '50px', // Adjust the height to make it round
        color: color,
        borderRadius: '50%', // Set border-radius to 50% for a round shape
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin:'auto'
        }}>{id}</p>
      <p style={{marginTop:'15px'}} className='white'>{title}</p>
      <p className='white'>{description}</p>
</div>
</Link>

  )
}
