import { useState } from 'react';
import OpenAI from 'openai';
import PrograssBar from './PrograssBar';

export default function ImgGenerator() {
  const apiKey = process.env.REACT_APP_API_KEY;
  const [prompt, setPrompt] = useState('');
  const [result, setResult] = useState('');
  const [loading, setLoading] = useState(false);

  const openai = new OpenAI({
    apiKey: apiKey,
    dangerouslyAllowBrowser: true,
  });
  const generateImage = async () => {
    setLoading(true);
    const response = await openai.images.generate({
      model: 'dall-e-3',
      prompt: prompt,
    });
    console.log(response);
    setLoading(false);
    setResult(response.data[0].url);
  };

  return (
    <div style={{ marginTop: '25px', minHeight: '91vh', }}>
      <div style={{margin:'25px'}}>
        <textarea
          className='text-input'
          placeholder='Enter a prompt'
          onChange={(e) => setPrompt(e.target.value)}
          row='5'
          cols='50'
        />
        <button className='button' onClick={generateImage}>
          Generate Image
        </button>
        {loading ? (
          <div style={{ margin: 'auto' }}>{<PrograssBar />}</div>
        ) : (
          <></>
        )}
        {result.length > 0 ? (
          <img className='result-image' src={result} alt='Generated Image' />
        ) : (
          <></>
        )}
      </div>
      <p className='footer'>Powered by OpenAI</p>
    </div>
  );
}
