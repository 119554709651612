import { useEffect, useState } from 'react'
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { MainContainer, ChatContainer, MessageList, Message, MessageInput, TypingIndicator } from '@chatscope/chat-ui-kit-react';
import { Input, Button } from '@mui/joy'

const apiKey = process.env.REACT_APP_API_KEY;

// "Explain things like you would to a 10 year old learning how to code."

export default function TextAI() {

    const [prospective, setProspective] = useState("")
    const [searched, setSearched] = useState([])
    const [messages, setMessages] = useState([
        {
            message: "Ask me anything!",
            sentTime: "just now",
            sender: "ChatGPT"
        }
    ]);
    const [isTyping, setIsTyping] = useState(false);
    const systemMessage = { //  Explain things like you're talking to a software professional with 5 years of experience.
        "role": "system", "content": prospective
    }
    const handleSend = async (message) => {
        const newMessage = {
            message,
            direction: 'outgoing',
            sender: "user"
        };

        const newMessages = [...messages, newMessage];
        const newSearched = [...searched, message]
        setMessages(newMessages);
        setSearched(newSearched)

        // Initial system message to determine ChatGPT functionality
        // How it responds, how it talks, etc.
        setIsTyping(true);
        await processMessageToChatGPT(newMessages);
    };

    async function processMessageToChatGPT(chatMessages) { // messages is an array of messages
        // Format messages for chatGPT API
        // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
        // So we need to reformat

        let apiMessages = chatMessages.map((messageObject) => {
            let role = "";
            if (messageObject.sender === "ChatGPT") {
                role = "assistant";
            } else {
                role = "user";
            }
            return { role: role, content: messageObject.message }
        });


        // Get the request body set up with the model we plan to use
        // and the messages which we formatted above. We add a system message in the front to'
        // determine how we want chatGPT to act. 
        const apiRequestBody = {
            "model": "gpt-3.5-turbo",
            "messages": [
                systemMessage,  // The system message DEFINES the logic of our chatGPT
                ...apiMessages // The messages from our chat with ChatGPT
            ]
        }

        await fetch("https://api.openai.com/v1/chat/completions",
            {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + apiKey,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(apiRequestBody)
            }).then((data) => {
                return data.json();
            }).then((data) => {
                console.log(data);
                setMessages([...chatMessages, {
                    message: data.choices[0].message.content,
                    sender: "ChatGPT"
                }]);
                setIsTyping(false);
            });
    }

    const handleChange = (e) => {
        setProspective(e.target.value)
    }

    return (
        <div style={{ marginTop: '25px', height: '91vh' }}>
         <div style={{ position: 'relative', color: 'white', width: '80vw', margin: 'auto', padding: '1rem', zIndex: '9', fontWeight: 'normal' }}>
               <p className='ophie'>
                How would you like me to speak with you : //
                </p> 
                <Input onChange={handleChange} placeholder='Explain things like you would to a 10 years old.' />
            </div>
            <div style={{ display: 'flex', margin: 'auto', width: '90vw' }}>
                <div className='gptContainer'>
                    <MainContainer className='mainContainer'>
                        <ChatContainer className='chatCantainer'>
                            <MessageList
                                className='messageList'
                                scrollBehavior="smooth"
                                typingIndicator={isTyping ? <TypingIndicator content="Feching response...." /> : null}
                            >
                                {messages.map((message, i) => {
                                    return <Message key={i} model={message} />
                                })}
                            </MessageList>
                            <MessageInput placeholder="Type message here" onSend={handleSend} />
                        </ChatContainer>
                    </MainContainer>
                </div>
                <div className='mobileHide previousPrompt' style={{ textAlign:'right', backgroundColor:'white', margin:'1px'}}>
                    <p className='ophie'>
                        Previous Prompt
                    </p>

                    {
                        searched.map((item, index) =>
                            <p style={{ textAlign: 'right', marginTop: '0', }} key={index}>
                                <button
                                    style={{ background: '#c6e3fa', border: 'none', borderRadius: '5px', padding: '10px' }}
                                    onClick={() => handleSend(item)}>{item}</button>
                            </p>)
                    }
                </div></div >
        </div >
    )
}
