import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function PrograssCircle() {
  return (
    <div style={{ margin: 'auto' }}>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <CircularProgress className='prograssCircle' />
      </Box>
    </div>
  );
}
