import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import { Link } from 'react-router-dom';
// import { AuthContext } from '../context/AuthContext';
import AccountMenu from './AccountMenu';

export default function Header(props) {
  const { currentUser } = props;
  // const { logout } = useContext(AuthContext);

  // const handleLogout = () => {
  //   logout();
  // };

  return (
    <div id='header'>
      <h3 className='ophie white'>Ophie's</h3>
      <div style={{ display: 'flex', backgroundColor:'white', height:'50vh', position:'absolute',zIndex:'2' }}>
        {/* <Link
          to='/auth'
          style={{
            margin: 'auto',
            paddingLeft: '25px',
            paddingRight: '25px',
          }}
        >
          {currentUser?.success ? (
            <AccountMenu
              logout={handleLogout}
              firstChar={currentUser.firstChar}
            />
          ) : (
            <div className='fontAwesomeIcon' to='/auth'>
              <FontAwesomeIcon icon={faUser} size='lg' />
            </div>
          )}
        </Link> */}
      </div>
    </div>
  );
}
