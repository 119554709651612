import React from 'react'
import { useNavigate } from 'react-router-dom';
import blueUniverse from '../asset/blueUniverse.jpeg';
import city from '../asset/city.jpg'

export default function ImageAiContainer() {
    const navigate = useNavigate();
    const handleButtonClick = (location) => {
        // Navigate to the 'imageai' route when the button is clicked
        navigate(location);
      };
  return (
    <div id='imageai' className='imageAiContainer' 
        style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: `url(${city})`,
        backgroundSize: 'fit',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        height: '400px',
        color: 'white',
        }}>

        <h4>WELCOME</h4>
        <h4>CREATE STUNNING IMAGES</h4>
        <p>Unleash your creativity with our Image Generator. Generate unique and visually appealing images with just a few clicks.</p>
        <button className='explore-button' onClick={() => handleButtonClick('/imageai')}> Generate Image</button>

    </div>
  )
}
