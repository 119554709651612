import { useContext, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Header from './Header';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthContext } from '../context/AuthContext';
import AccountMenu from './AccountMenu';
import blueUniverse from '../asset/blueUniverse.jpeg';


function Navigation(props) {
  const { logout } = useContext(AuthContext);
  const { currentUser } = props;
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleOffcanvasClose = () => {
    setShowOffcanvas(false);
  };

  return (
    <div id='navigation'>
      <Header currentUser={currentUser} />
      <Navbar key='navbar' expand={'lg'} className='mb-3' >
        <Container >
            {/* <div style={{ display: 'flex' }}>
              <Link className='mobileHide mobileHideItem' to='/auth'>
                {currentUser?.success ? (
                  <AccountMenu
                    logout={logout}
                    firstChar={currentUser.firstChar}
                  />
                ) : (
                  <FontAwesomeIcon icon={faUser} />
                )}
              </Link>
            </div> */}
            <Navbar.Toggle
            style={{backgroundColor:'white', position:'absolute', top:'-55px', right: '-180px'}}
              aria-controls={`offcanvasNavbar-expand-lg`}
              onClick={() => setShowOffcanvas(!showOffcanvas)}
            />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement='end'
            show={showOffcanvas}
            onHide={handleOffcanvasClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`} >
                Menu
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className='justify-content-around flex-grow-1 pe-3'>
                <Link to='/' onClick={handleOffcanvasClose}  style={{fontWeight:'bold'}}>
                  Home
                </Link>
              </Nav>
              <Nav className='justify-content-around flex-grow-1 pe-3'>
                <Link to='/' onClick={handleOffcanvasClose} style={{fontWeight:'bold'}}>
                  About
                </Link>
              </Nav><Nav className='justify-content-around flex-grow-1 pe-3'>
                <Link to='/' onClick={handleOffcanvasClose} style={{fontWeight:'bold'}}>
                  AI Tool
                </Link>
              </Nav><Nav className='justify-content-around flex-grow-1 pe-3'>
                <Link to='/' onClick={handleOffcanvasClose} style={{fontWeight:'bold'}}>
                  Categories
                </Link>
              </Nav>
              <Nav className='justify-content-around flex-grow-1 pe-3'>
                <Link to='/' onClick={handleOffcanvasClose} style={{fontWeight:'bold'}}>
                  Contact Us
                </Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
      <div className='pb-3'>  
        <h1 className='white'>FILM PARADISE</h1>
        <p className='white'>UNLEASH YOUR CREATIVITY</p>
      </div>
    </div>
  );
}

export default Navigation;
