import React from 'react';
import { Input, Button } from '@mui/joy';

const Search = ({ onChange }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-start', marginBottom: '15px' }}>
      <Input onChange={onChange} placeholder='Search' style={{ width: '70vw' }} />
      {/* <Button style={{ marginLeft: '5px', width: '15vw' }} disabled>Search</Button> */}
    </div>
  );
};

export default Search;