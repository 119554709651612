import { createContext, useState } from 'react';
import axios from 'axios';
import { url } from '../url';

export const ProductContext = createContext();
export function ProductContextProvider({ children }) {
  const [products, setProducts] = useState([]);
  const [cartItems, setCartItems] = useState([]);

  const getProducts = async () => {
    await axios.get(url + '/all_products').then((response) => {
      setProducts(response.data);
    });
    return products;
  };

  const getProductById = async (id) => {
    await axios.get(url + '/product/' + id).then((response) => {
      const data = response;
      return data;
    });
  };

  return (
    <ProductContext.Provider
      value={{
        products,
        cartItems,
        setCartItems,
        getProducts,
        getProductById,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

export default ProductContextProvider;
