import React from 'react'

export default function FooterContainer() {
  return (
    <div className='footerContainer white'>
        <div className='footerContent'>
            <h6 style={{textAlign:'right', marginBottom:'25px', fontWeight:'bold'}}>ABOUT</h6>
            <p>your gateway to cutting-edge artificial intelligence. Unleash creativity with image and text generation powered by advanced models. Engage in natural language conversations with ChatGPT. Explore the possibilities and elevate your projects with our innovative AI features. Get started today!</p>
        </div>
        <div className='footerContent'>
            <h6 style={{textAlign:'right', marginBottom:'25px', fontWeight:'bold'}}>ADDRESS</h6>
        </div>
        <div className='footerContent'>
            <h6 style={{textAlign:'right', marginBottom:'25px', fontWeight:'bold'}}>HOURS</h6>
        </div>
    </div>
  )
}
