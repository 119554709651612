import React, { useContext, useState } from 'react';
import { AuthContext } from '../context/AuthContext';
import { useLogin, useSignUp } from '../services/auth';

export default function Login(props) {
  let [authMode, setAuthMode] = useState('signin');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [dob, setDob] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const { errorMessage } = useContext(AuthContext);

  const changeAuthMode = () => {
    setAuthMode(authMode === 'signin' ? 'signup' : 'signin');
  };

  const { mutateAsync: signUpMutation } = useSignUp({
    onSuccess: (response) => {
      console.log('signup success');
    },
  });

  const { mutateAsync: loginMutation } = useLogin({
    onSuccess: (response) => {
      console.log('login success');
    },
  });

  if (authMode === 'signin') {
    return (
      <div className='Auth-form-container'>
        <form className='Auth-form'>
          <div className='Auth-form-content'>
            <p className='errorMessage'>{errorMessage}</p>
            <h3 className='Auth-form-title'>Sign In</h3>

            <span>Don't have an account ? </span> 
            <span className='link-primary' onClick={changeAuthMode}>
             Sign Up
            </span>

            <div className='form-group mt-3'>
              <label>Email address</label>
              <input
                type='email'
                className='form-control mt-1'
                placeholder='Enter email'
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className='form-group mt-3'>
              <label>Password</label>
              <input
                type='password'
                className='form-control mt-1'
                placeholder='Enter password'
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className='d-grid gap-2 mt-3'>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  loginMutation({
                    email,
                    password,
                  });
                }}
                className='btn btn-primary'
              >
                Login
              </button>
            </div>
            {/*<p className='text-center mt-2'>
              Forgot <a href='#'>password?</a>
              </p>*/}
          </div>
        </form>
      </div>
    );
  }

  return (
    <div className='Auth-form-container'>
      <form className='Auth-form'>
        <div className='Auth-form-content'>
          <p className='errorMessage'>{errorMessage}</p>
          <h3 className='Auth-form-title'>Sign Up</h3>
          <div className='text-center'>
            Already registered?{' '}
            <span className='link-primary' onClick={changeAuthMode}>
              Sign In
            </span>
          </div>
          <div className='form-group mt-3'>
            <label>Email address</label>
            <input
              type='email'
              className='form-control mt-1'
              placeholder='Email Address'
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className='form-group mt-3'>
            <label>Password</label>
            <input
              type='password'
              className='form-control mt-1'
              placeholder='Password'
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className='form-group mt-3'>
            <label>First Name</label>
            <input
              type='text'
              className='form-control mt-1'
              placeholder='e.g Jane'
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className='form-group mt-3'>
            <label>Last Name</label>
            <input
              type='text'
              className='form-control mt-1'
              placeholder='e.g Doe'
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
          <div className='form-group mt-3'>
            <label>DOB</label>
            <input
              type='text'
              className='form-control mt-1'
              placeholder='e.g 1/1/1980'
              onChange={(e) => setDob(e.target.value)}
            />
          </div>
          <div className='form-group mt-3'>
            <label>Phone Number</label>
            <input
              type='text'
              className='form-control mt-1'
              placeholder='e.g xxx-xxx-xxxx'
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div className='d-grid gap-2 mt-3'>
            <button
              onClick={(e) => {
                e.preventDefault();
                signUpMutation({
                  firstName,
                  lastName,
                  email,
                  password,
                  dob,
                  phoneNumber,
                });
              }}
              className='btn btn-primary'
            >
              Submit
            </button>
          </div>
          <p className='text-center mt-2'>
            Forgot <a href='#'>password?</a>
          </p>
            </div>
      </form>
    </div>
  );
}
