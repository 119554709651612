import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import React from 'react';
import { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import { ProductContextProvider } from './context/ProductContext';

import { useCurrentUser } from './services/auth';
import axios from 'axios';
import ProtectedRoute from './ProtectedRoute';
import PrograssBar from './component/PrograssBar';

import Home from './page/Home';
import UserProfile from './page/UserProfile';

import Navigation from './component/Nav';
import Login from './component/Login';
import ImgGenerator from './component/ImgGenerator';
import TextAI from './component/TextAi';

import blueUniverse from './asset/blueUniverse.jpeg';

function App() {
  axios.defaults.withCredentials = true;

  // const { data, refetch, isLoading } = useCurrentUser();

  // useEffect(() => {
  //   refetch();
  // }, []);

  // if (isLoading) {
  //   return <div>{<PrograssBar />}</div>;
  // }

  return (
    <div
      className='App'
      style={{
        backgroundColor:'lavender'
      }}
    >
      {/* <AuthContextProvider> */}
        <ProductContextProvider>
          <div style={{ margin: 'auto' }}>
            <div style={{
              background: `url(${blueUniverse})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
              }}>

            <Navigation  />
            </div>
          {/* <Navigation currentUser={data} /> */}
            <Routes>
            {/* <Route
                path='/'
                element={<Home currentUser={data} refetch={refetch} />}
              /> */}
              <Route
                path='/'
                element={<Home  />}
              />
              <Route path='/auth' element={<Login />} />
              <Route path='/imageai' element={<ImgGenerator/>}/>
              <Route path='/textai' element={<TextAI/>}/>

              {/* <Route
                path='/profile'
                element={
                  <ProtectedRoute success={data?.success}>
                    <UserProfile currentUser={data} />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/imageai'
                element={
                  <ProtectedRoute success={data?.success}>
                    <ImgGenerator currentUser={data} />
                  </ProtectedRoute>
                }
              /> */}
            </Routes>
          </div>
        </ProductContextProvider>
      {/* </AuthContextProvider> */}
    </div>
  );
}

export default App;
