import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import axios from 'axios';

import { AuthContext } from '../context/AuthContext';
import { url } from '../url';

export const useLogin = (opts = {}) => {
  const { login } = useContext(AuthContext);
  const mutation = useMutation(
    ({ email, password }) =>
      login(email, password).catch(
        (err) => alert(err)
        // alert(`Email or password is invalid! Please double check your credentials`),
      ),
    { ...opts },
    {
      onError: (err) => {
        window.location.reload();
      },
    }
  );
  return mutation;
};

export const useSignUp = (opts = {}) => {
  const { signUp } = useContext(AuthContext);
  const mutation = useMutation(
    ({ firstName, lastName, email, password, dob, phoneNumber }) =>
      signUp(firstName, lastName, email, password, dob, phoneNumber).catch(
        () => {
          alert(`something went wrong , please try again`);
        }
      ),
    { ...opts },
    {
      onError: (err) => {
        window.location.reload();
      },
    }
  );
  return mutation;
};

// const getCurrentUser = async () => {
//   let user = {};
//   let letter = '';
//   let firstChar = '';
//   await axios.get(url + '/validate').then((response) => {
//     if (response.data.success === false) return;
//     user = response.data;
//     letter = user.data.firstName;
//     firstChar = letter.charAt(0);
//     user.firstChar = firstChar;
//   });
//   return user;
// };
// export const useCurrentUser = () => {
//   const { data, isLoading, isError, refetch } = useQuery(
//     ['currentUser'],
//     getCurrentUser
//   );
//   return { data, isLoading, isError, refetch };
// };
