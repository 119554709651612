import React, { useContext, useEffect , useState} from 'react';
// import Button from '@mui/material/Button';
import { Input, Button } from '@mui/joy'
import Section from '../component/Section'
import Search from '../component/Search'
import ColorCard from '../component/ColorCard'
import TextAiContainer from '../component/TextAiContainer'
import ImageAiContainer from '../component/ImageAiContainer';
import FooterContainer from '../component/FooterContainer';


export default function Home(props) {
  const data = [
    { title:'Explore the Power of ChatGPT',
      description:'Engage in natural language conversations with our advanced ChatGPT.Ask questions, get creative, and experience the capabilities of language AI.',
      action:'/textai',
      buttonTitle:'Start Chatting'
    },
    { title:'Create Stunning Images', 
      description:'Unleash your creativity with our Image Generator. Generate unique and visually appealing images with just a few clicks.',
      action:'/imageai',
      buttonTitle:'Image generator'
    }
  ]

  const catagory = [
    {id:'01',title:'CHATGPT',description:'Engage in natural language conversation...',color:'rgba(213, 0, 110, 0.92)', to:'textai'},
    {id:'02',title:'IMAGES',description:'Unleash your creativity with our Image Generator..',color:'darkBlue', to:'imageai'},
    {id:'03',title:'Coming soon...',description:`Under Construction...`,color:'rgba(213, 0, 110, 0.92)', to:''}
  ]

  const [searchInput, setSearchInput] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const filtered = data.filter(item =>
      item.title.toLowerCase().includes(searchInput.toLowerCase())
    );
    setFilteredData(filtered);
  }, [searchInput]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };
  
  return (
    <div>
      
      {/* <div style={{display:'flex', justifyContent:'flex-start', marginBottom:'15px'}}  >
        <Search onChange={handleSearchChange} />
      </div> */}
      <div className='colorCard' style={{display:'flex', justifyContent:'space-around'}}>
        {
          catagory.map((el,index) => {

            return <ColorCard key={index} id={el.id} title={el.title} description={el.description} color={el.color} to={el.to}/>
          })
        }
      </div>
      <div>
        <TextAiContainer />
        <ImageAiContainer />
      {/* {filteredData.length > 0 ? (
          filteredData.map((el, index) => (
            <Section key={index} title={el.title} description={el.description} action={el.action} buttonTitle={el.buttonTitle} />
          ))
        ) : (
          <p>No results found.</p>
        )} */}
      </div>
      <FooterContainer />
       
    </div>
  );
}
