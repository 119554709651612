import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { url } from '../url';

export const AuthContext = createContext({ getCurrentUser: () => {} });

export function AuthContextProvider({ children }) {
  const navigate = useNavigate();

  const [token, settoken] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  // const [isLogin, setIsLogin] = useState(false)

  const login = async (email, password) => {
    await axios
      .post(url + '/login', {
        email: email,
        password: password,
      })
      .then((response) => {
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          // setIsLogin(true)
          settoken(response.data);
          setErrorMessage('');
          navigate('/');
          window.location.reload();
        }
      });
  };

  const signUp = async (
    firstName,
    lastName,
    email,
    password,
    dob,
    phoneNumber
  ) => {
    await axios
      .post(url + '/signup', {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        dob: dob,
        phoneNumber: phoneNumber,
      })
      .then((response) => {
        if (response.data.error) {
          setErrorMessage(response.data.error);
        } else {
          login(email, password);
        }
      });
  };

  const logout = async () => {
    console.log('click');
    await axios.post(url + `/logout`).then((response) => {
      console.log(response);
      console.log('logout success');
      // setIsLogin(false)
      settoken('');
      // setUser({})
      setErrorMessage('');
      console.log('logout');
      window.location.reload();
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        login,
        errorMessage,
        signUp,
        // user,
        // isLogin,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;
